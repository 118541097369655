/* hide scrollbar but allow scrolling */
.column {
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  overflow-y: scroll;
}

.column::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}
