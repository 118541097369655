.avatarCircle {
    opacity: 1;
    display: block;
    width: 100%;
    height: auto;
    transition: .5s ease;
    backface-visibility: hidden;
   }
  
  .middleEditIcon {
    transition: .5s ease;
    opacity: 0;
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -150%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
  }
  
  .container:hover .avatarCircle {
    opacity: 0.3;
  }
  
  .container:hover .middleEditIcon {
    opacity: 1;
  }
  