#gridContainer {
    height: 440px;
}

.options {
    padding: 20px;
    margin-top: 20px;
    background-color: rgba(191, 191, 191, 0.15);
}

.caption {
    font-size: 18px;
    font-weight: 500;
}

.option {
    margin-top: 10px;
}

.option > span {
    margin-right: 10px;
}

.option > .dx-selectbox {
    display: inline-block;
    vertical-align: middle;
}

body {
    font-family: Arial, sans-serif;
    line-height: 1.6;
    margin: 20px;
    padding: 0;
    background-color: #f9f9f9;
    color: #333;
}
h1, h2 {
    color: #0056b3;
}
h1 {
    text-align: center;
}
p {
    margin: 10px 0;
}
ul {
    margin: 10px 0 10px 20px;
}
a {
    color: #0056b3;
    text-decoration: none;
}
a:hover {
    text-decoration: underline;
}
.containerText {
    max-width:800px;
    margin: 0 auto;
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}