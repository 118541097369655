body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#nofificacionContainer::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
#nofificacionContainer::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
#nofificacionContainer::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
#nofificacionContainer::-webkit-scrollbar-thumb:hover {
  background: #555;
} 

.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.Container {
  width: "100%";
  height: "100%";
}

.LayoutLeft {
  background-color: #2b3c4d;
  height: 100vh;
  width: 200px;
  float: left;
  position: fixed;
}

.DivLogo {
  background-color: #202e3c;
  height: 70px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.ImgLogo {
  width: 167px;
}

.LayoutRightContent {
  height: 100vh;
}

.LayoutHeader {
  background-color: #fff;
  height: 70px;
  border-bottom-width: 2px;
  border-bottom-color: blue;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12),
    0 3px 1px -2px rgba(0, 0, 0, 0.2);
  margin-bottom: 10px;
  margin-left: 0;
  margin-right: 0;
  padding-top: 4px;
  padding-left: 0;
  text-align: center;
}

.LayoutContent {
  padding-left: 210px;
  padding-right: 10px;
}

.Titulo {
  text-align: center;
  padding-top: 5px;
}

.DivOptions {
  padding-top: 10px;
}

.FloatBottomDiv {
  position: fixed;
}

.FormularioContainer {
  width: 500px;
  margin: 0 auto;
}

.FormularioTextMultiline {
  width: 100%;
  margin: 10px !important;
}

#gridContainer {
    height: 440px;
}

.options {
    padding: 20px;
    margin-top: 20px;
    background-color: rgba(191, 191, 191, 0.15);
}

.caption {
    font-size: 18px;
    font-weight: 500;
}

.option {
    margin-top: 10px;
}

.option > span {
    margin-right: 10px;
}

.option > .dx-selectbox {
    display: inline-block;
    vertical-align: middle;
}
.FloatBottomDiv{
    right: 0;
    bottom: 0;
    position: absolute;
    padding: 30px;
}
li{
   list-style-type: none;
}

ul{
    padding:5px 10px;
}

.dragCard{
    padding:15px 15px;
    margin-bottom:15px;
    border: #c4c4c4 dashed 1.5px;
    cursor:-webkit-grab;
    cursor:grab;
}

.dragCard>a,a:visited{
    text-decoration: none;
    color:#000;
}

.flujoCard:hover{
    background-color:#217ecf;
    color:#fff;
    cursor:pointer;
}
.flujoCard>.nombreFlujo{
    margin-top:10px;
    text-align:center;
    font-weight: bold;
}
.flujoCard>.descripcionFlujo{
    margin-top:10px;
    text-align:center;
}
.flujoCard>.numeroTareasFlujo{
    margin-top:10px;
    text-align:center;
}
.flujoCard>.nombreEntidadFlujo{
    margin-top:10px;
    padding-bottom:5px;
    text-align:center;
    font-size: xx-small;
    font-style: italic;
}
.flujoCard>.iconoFlujoContainer{
    text-align:center;
}
.plantillaIcono{
    width:80px;
    height: 80px;
    text-align:center;
    margin:auto
}

/* .MuiPaper-root{
    width:350px;
    max-width: 350px;
} */

.lateralDerecho{
    width:350px !important;
    max-width: 350px !important;
}
li{
   list-style-type: none;
}

ul{
    padding:5px 10px;
}

.dragCard{
    padding:15px 15px;
    margin-bottom:15px;
    border: #c4c4c4 dashed 1.5px;
    cursor:-webkit-grab;
    cursor:grab;
}

.dragCard>a,a:visited{
    text-decoration: none;
    color:#000;
}

.flujoCard:hover{
    background-color:#217ecf;
    color:#fff;
    cursor:pointer;
}
.flujoCard>.nombreFlujo{
    margin-top:10px;
    text-align:center;
    font-weight: bold;
}
.flujoCard>.descripcionFlujo{
    margin-top:10px;
    text-align:center;
}
.flujoCard>.numeroTareasFlujo{
    margin-top:10px;
    text-align:center;
}
.flujoCard>.nombreEntidadFlujo{
    margin-top:10px;
    padding-bottom:5px;
    text-align:center;
    font-size: xx-small;
    font-style: italic;
}
.flujoCard>.iconoFlujoContainer{
    text-align:center;
}
.plantillaIcono{
    width:80px;
    height: 80px;
    text-align:center;
    margin:auto
}

/* .MuiPaper-root{
    width:350px;
    max-width: 350px;
} */

.lateralDerecho{
    width:350px !important;
    max-width: 350px !important;
}
li{
   list-style-type: none;
}

ul{
    padding:5px 10px;
}

.dragCard{
    padding:15px 15px;
    margin-bottom:15px;
    border: #c4c4c4 dashed 1.5px;
    cursor:-webkit-grab;
    cursor:grab;
}

.dragCard>a,a:visited{
    text-decoration: none;
    color:#000;
}

.flujoCard:hover{
    background-color:#217ecf;
    color:#fff;
    cursor:pointer;
}
.flujoCard>.nombreFlujo{
    margin-top:10px;
    text-align:center;
    font-weight: bold;
}
.flujoCard>.descripcionFlujo{
    margin-top:10px;
    text-align:center;
}
.flujoCard>.numeroTareasFlujo{
    margin-top:10px;
    text-align:center;
}
.flujoCard>.nombreEntidadFlujo{
    margin-top:10px;
    padding-bottom:5px;
    text-align:center;
    font-size: xx-small;
    font-style: italic;
}
.flujoCard>.iconoFlujoContainer{
    text-align:center;
}
.plantillaIcono{
    width:80px;
    height: 80px;
    text-align:center;
    margin:auto
}

/* .MuiPaper-root{
    width:350px;
    max-width: 350px;
} */

.lateralDerecho{
    width:350px !important;
    max-width: 350px !important;
}
.super-treeview * {
  box-sizing: border-box;
  -webkit-box-sizing: border-box; }

.super-treeview {
  font-size: 100%; }
  .super-treeview > div > .super-treeview-no-children-transition {
    transition-property: all; }
  .super-treeview > div > .super-treeview-no-children-transition-enter {
    opacity: 0;
    height: 0;
    overflow: hidden; }
    .super-treeview > div > .super-treeview-no-children-transition-enter.super-treeview-no-children-transition-enter-active {
      opacity: 1;
      height: 0; }
  .super-treeview > div > .super-treeview-no-children {
    overflow: hidden; }
    .super-treeview > div > .super-treeview-no-children > .super-treeview-no-children-content {
      margin: 2px 0; }
  .super-treeview > div > .super-treeview-node {
    overflow: hidden; }
    .super-treeview > div > .super-treeview-node > .super-treeview-children-container {
      margin-left: 8px;
      padding-left: 20px;
      border-left: 1px solid #000; }
      .super-treeview > div > .super-treeview-node > .super-treeview-children-container > .super-treeview-loading {
        padding: 0 3px;
        margin: 2px 0; }
    .super-treeview > div > .super-treeview-node > .super-treeview-node-content {
      padding: 0 3px;
      margin: 2px 0; }
      .super-treeview > div > .super-treeview-node > .super-treeview-node-content > label {
        vertical-align: top;
        text-overflow: ellipsis;
        width: calc(100% - 55px);
        overflow: hidden;
        white-space: nowrap;
        display: inline-block;
        margin: 0;
        font-weight: normal;
        font-size: 100%; }
      .super-treeview > div > .super-treeview-node > .super-treeview-node-content > input[type=checkbox] {
        float: left;
        margin: 4px 5px 0 0;
        line-height: normal; }
      .super-treeview > div > .super-treeview-node > .super-treeview-node-content > .super-treeview-triangle-btn {
        float: left;
        margin-right: 5px;
        cursor: pointer; }
      .super-treeview > div > .super-treeview-node > .super-treeview-node-content > .super-treeview-triangle-btn-none {
        cursor: none; }
      .super-treeview > div > .super-treeview-node > .super-treeview-node-content > .super-treeview-triangle-btn-right {
        width: 8px;
        height: 10px;
        margin-top: 2px;
        margin-left: 2px;
        border-top: 5px solid transparent;
        border-left: 6px solid #000;
        border-bottom: 5px solid transparent; }
      .super-treeview > div > .super-treeview-node > .super-treeview-node-content > .super-treeview-triangle-btn-down {
        width: 10px;
        height: 8px;
        margin-top: 5px;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 6px solid #000; }
      .super-treeview > div > .super-treeview-node > .super-treeview-node-content > .delete-btn {
        float: right;
        cursor: pointer; }
  .super-treeview > div > .super-treeview-node-transition {
    transition-property: all; }
  .super-treeview > div > .super-treeview-node-transition-enter {
    opacity: 0; }
    .super-treeview > div > .super-treeview-node-transition-enter.super-treeview-node-transition-enter-active {
      opacity: 1; }
  .super-treeview > div > .super-treeview-node-transition-exit {
    opacity: 1; }
    .super-treeview > div > .super-treeview-node-transition-exit.super-treeview-node-transition-exit-active {
      opacity: 0;
      background-color: red; }

li.fgoxtS {
  background-color: #2c3e50;
}

.FloatBottomDiv {
  position: fixed;
  bottom: 20px;
  right: 20px;
}

li.fgoxtS {
  background-color: #2c3e50;
}

.FloatBottomDiv {
  position: fixed;
  bottom: 20px;
  right: 20px;
}

.MuiContainer-maxWidthMd{
  max-width: 100% !important;
}
.carouselContainer {
  position: relative;
  padding: 0.25rem;
  /* max-width: 90%; */
  /* margin: auto; */
}

.dot {
  font-size: 1.5rem;
  color: #CFD2CF;
  border: none !important;
  background: transparent;
}

.carousel__dot--selected {
  color: #69bd4b;
  background: transparent;
}

.carouselDotGroup {
  position: absolute;
  top: 90%;
  -webkit-transform: translateY(-10%);
          transform: translateY(-10%);
  width: 100%;
  text-align: "center";
  margin: auto;
}

.carouselLeftButton {
  background-color: white;
  margin: 10px;
  width: 30px;
  height: 30px;
  padding: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  border-radius: 50%;
  display: inline-block;
  border: 2px solid gray;
  color: rgb(14, 13, 13);
  transition: 0.4s;

  position: absolute;
  top: 50%;
  left: 0;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.carouselLeftButton:hover, .carouselRightButton:hover {
  background-color: #748DA6;
  color: white;
}

.carouselRightButton {
  background-color: white;
  margin: 10px;
  width: 30px;
  height: 30px;
  padding: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  border-radius: 50%;
  display: inline-block;
  border: 2px solid gray;
  color: rgb(14, 13, 13);
  transition: 0.4s;

  position: absolute;
  top: 50%;
  right: 0;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.buttonBack {
  position: absolute;
  top: 50%;
  left: 0;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.buttonNext {
  position: absolute;
  top: 50%;
  right: 0;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.carrouselbutton::hover {
  border: 3px solid aqua;
}

.MuiTimelineItem-missingOppositeContent:before{
    padding:0 !important;
    display:none !important;
}

.MuiStepLabel-label {
    color: #BDBDBD !important;
}

.MuiStepLabel-active {
    color: #8057D7 !important;
}

.MuiStepLabel-completed {
    color: #0B0B0B !important;
}
li.fgoxtS {
  background-color: #2c3e50;
}

.FloatBottomDiv {
  position: fixed;
  bottom: 20px;
  right: 20px;
}

#gridContainer {
    height: 440px;
}

.options {
    padding: 20px;
    margin-top: 20px;
    background-color: rgba(191, 191, 191, 0.15);
}

.caption {
    font-size: 18px;
    font-weight: 500;
}

.option {
    margin-top: 10px;
}

.option > span {
    margin-right: 10px;
}

.option > .dx-selectbox {
    display: inline-block;
    vertical-align: middle;
}
li.fgoxtS {
  background-color: #2c3e50;
}

.FloatBottomDiv {
  position: fixed;
  bottom: 20px;
  right: 20px;
}

#gridContainer {
    height: 440px;
}

.options {
    padding: 20px;
    margin-top: 20px;
    background-color: rgba(191, 191, 191, 0.15);
}

.caption {
    font-size: 18px;
    font-weight: 500;
}

.option {
    margin-top: 10px;
}

.option > span {
    margin-right: 10px;
}

.option > .dx-selectbox {
    display: inline-block;
    vertical-align: middle;
}
.avatarCircle {
    opacity: 1;
    display: block;
    width: 100%;
    height: auto;
    transition: .5s ease;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
   }
  
  .middleEditIcon {
    transition: .5s ease;
    opacity: 0;
    position: relative;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -150%);
            transform: translate(-50%, -150%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
  }
  
  .container:hover .avatarCircle {
    opacity: 0.3;
  }
  
  .container:hover .middleEditIcon {
    opacity: 1;
  }
  
#pdf-container {
  /* width: 100%; */
  margin-top: 60px;
  margin-bottom: 20px;
  /* display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center; */
}

.page {
  page-break-after: always;
  position: relative;
  margin-top: 10px;
}
.page canvas{
  page-break-after: always;
  position: relative;
  margin-top: 10px;
  box-shadow: 8px 10px 24px -8px rgba(0,0,0,0.71);
  -webkit-box-shadow: 8px 10px 24px -8px rgba(0,0,0,0.71);
  -moz-box-shadow: 8px 10px 24px -8px rgba(0,0,0,0.71);
}

.pdf-link {
  position: absolute;
  background-color: rgba(26, 122, 224, 0.154);
}
#gridContainer {
    height: 440px;
}

.options {
    padding: 20px;
    margin-top: 20px;
    background-color: rgba(191, 191, 191, 0.15);
}

.caption {
    font-size: 18px;
    font-weight: 500;
}

.option {
    margin-top: 10px;
}

.option > span {
    margin-right: 10px;
}

.option > .dx-selectbox {
    display: inline-block;
    vertical-align: middle;
}
#gridContainer {
    height: 440px;
}

.options {
    padding: 20px;
    margin-top: 20px;
    background-color: rgba(191, 191, 191, 0.15);
}

.caption {
    font-size: 18px;
    font-weight: 500;
}

.option {
    margin-top: 10px;
}

.option > span {
    margin-right: 10px;
}

.option > .dx-selectbox {
    display: inline-block;
    vertical-align: middle;
}
#gridContainer {
    height: 440px;
}

.options {
    padding: 20px;
    margin-top: 20px;
    background-color: rgba(191, 191, 191, 0.15);
}

.caption {
    font-size: 18px;
    font-weight: 500;
}

.option {
    margin-top: 10px;
}

.option > span {
    margin-right: 10px;
}

.option > .dx-selectbox {
    display: inline-block;
    vertical-align: middle;
}
/* hide scrollbar but allow scrolling */
.column {
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  overflow-y: scroll;
}

.column::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}

#gridContainer {
    height: 440px;
}

.options {
    padding: 20px;
    margin-top: 20px;
    background-color: rgba(191, 191, 191, 0.15);
}

.caption {
    font-size: 18px;
    font-weight: 500;
}

.option {
    margin-top: 10px;
}

.option > span {
    margin-right: 10px;
}

.option > .dx-selectbox {
    display: inline-block;
    vertical-align: middle;
}

body {
    font-family: Arial, sans-serif;
    line-height: 1.6;
    margin: 20px;
    padding: 0;
    background-color: #f9f9f9;
    color: #333;
}
h1, h2 {
    color: #0056b3;
}
h1 {
    text-align: center;
}
p {
    margin: 10px 0;
}
ul {
    margin: 10px 0 10px 20px;
}
a {
    color: #0056b3;
    text-decoration: none;
}
a:hover {
    text-decoration: underline;
}
.containerText {
    max-width:800px;
    margin: 0 auto;
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
