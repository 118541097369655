li.fgoxtS {
  background-color: #2c3e50;
}

.FloatBottomDiv {
  position: fixed;
  bottom: 20px;
  right: 20px;
}

.MuiContainer-maxWidthMd{
  max-width: 100% !important;
}