.MuiStepLabel-label {
    color: #BDBDBD !important;
}

.MuiStepLabel-active {
    color: #8057D7 !important;
}

.MuiStepLabel-completed {
    color: #0B0B0B !important;
}