li{
   list-style-type: none;
}

ul{
    padding:5px 10px;
}

.dragCard{
    padding:15px 15px;
    margin-bottom:15px;
    border: #c4c4c4 dashed 1.5px;
    cursor:grab;
}

.dragCard>a,a:visited{
    text-decoration: none;
    color:#000;
}

.flujoCard:hover{
    background-color:#217ecf;
    color:#fff;
    cursor:pointer;
}
.flujoCard>.nombreFlujo{
    margin-top:10px;
    text-align:center;
    font-weight: bold;
}
.flujoCard>.descripcionFlujo{
    margin-top:10px;
    text-align:center;
}
.flujoCard>.numeroTareasFlujo{
    margin-top:10px;
    text-align:center;
}
.flujoCard>.nombreEntidadFlujo{
    margin-top:10px;
    padding-bottom:5px;
    text-align:center;
    font-size: xx-small;
    font-style: italic;
}
.flujoCard>.iconoFlujoContainer{
    text-align:center;
}
.plantillaIcono{
    width:80px;
    height: 80px;
    text-align:center;
    margin:auto
}

/* .MuiPaper-root{
    width:350px;
    max-width: 350px;
} */

.lateralDerecho{
    width:350px !important;
    max-width: 350px !important;
}