.carouselContainer {
  position: relative;
  padding: 0.25rem;
  /* max-width: 90%; */
  /* margin: auto; */
}

.dot {
  font-size: 1.5rem;
  color: #CFD2CF;
  border: none !important;
  background: transparent;
}

.carousel__dot--selected {
  color: #69bd4b;
  background: transparent;
}

.carouselDotGroup {
  position: absolute;
  top: 90%;
  transform: translateY(-10%);
  width: 100%;
  text-align: "center";
  margin: auto;
}

.carouselLeftButton {
  background-color: white;
  margin: 10px;
  width: 30px;
  height: 30px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  display: inline-block;
  border: 2px solid gray;
  color: rgb(14, 13, 13);
  transition: 0.4s;

  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}
.carouselLeftButton:hover, .carouselRightButton:hover {
  background-color: #748DA6;
  color: white;
}

.carouselRightButton {
  background-color: white;
  margin: 10px;
  width: 30px;
  height: 30px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  display: inline-block;
  border: 2px solid gray;
  color: rgb(14, 13, 13);
  transition: 0.4s;

  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

.buttonBack {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.buttonNext {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

.carrouselbutton::hover {
  border: 3px solid aqua;
}
