#pdf-container {
  /* width: 100%; */
  margin-top: 60px;
  margin-bottom: 20px;
  /* display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center; */
}

.page {
  page-break-after: always;
  position: relative;
  margin-top: 10px;
}
.page canvas{
  page-break-after: always;
  position: relative;
  margin-top: 10px;
  box-shadow: 8px 10px 24px -8px rgba(0,0,0,0.71);
  -webkit-box-shadow: 8px 10px 24px -8px rgba(0,0,0,0.71);
  -moz-box-shadow: 8px 10px 24px -8px rgba(0,0,0,0.71);
}

.pdf-link {
  position: absolute;
  background-color: rgba(26, 122, 224, 0.154);
}